.te-toolbar1 {
  background-color: #dadada;
  height: 48px;
  vertical-align: middle;
  border-bottom: solid 1px #959595;
}

.te-toolbar2 {
  background-color: #d0d1e0;
  height: 32px;
  border-bottom: solid 1px #959595;
  padding-left: 10px;
}

.te-templateName {
  font-size: 20pt;
  margin-top: 8px;
}

.te-editbutton {
  color: #4976ec;
  cursor: pointer;
}

.te-closeArea {
  margin-top: 9px;
  margin-right: 4px;
}

.te-capture-header {
  background-color: #c2dffd;
  color: #235080;
  text-align: center;
  padding: 2px;
  border-bottom: solid 1px #235080;
}



.te-scan-header {
  background-color: #fdffb9;
  color: #58593a;
  text-align: center;
  padding: 2px;
  border-bottom: solid 1px #58593a;
}

.te-identifier-header {
  background-color: #b8e9ae;
  color: #245028;
  text-align: center;
  padding: 2px;
  border-bottom: solid 1px #58593a;
}

.te-split-header {
  background-color: #f0a7a7;
  color: #593a3a;
  text-align: center;
  padding: 2px;
  border-bottom: solid 1px #593a3a;
}

.te-table-header {
  background-color: #feb73b;
  color: #6d5731;
  text-align: center;
  padding: 2px;
  border-bottom: solid 1px #6d5731;
}

.te-capture-header {
  background-color: #3430a1d4;
  color: #ffffff;
  text-align: center;
  padding: 2px;
  border-radius: 5px;
  border-bottom: solid 1px #6d5731;
}

.te-edit-area {
  background-color: #ededed;
  border-bottom: solid 1px #cecece;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 8px;
}

.te-edit-area label {
  font-size: 10pt;
  padding: 0;
  margin: 0;
  margin-left: 4px;
}

.te-advance-options {
  padding: 4px;
  padding-left: 8px;
  background-color: #bfbfbf;
}

.te-node-tree {
  background-color: #313e4b;
  color: #fff;
  padding: 6px;
}

.te-node-1 {
  cursor: pointer;
  margin-left: 4px;
}

.te-node-2 {
  cursor: pointer;
  margin-left: 12px;
}
.te-node-3 {
  cursor: pointer;
  margin-left: 18px;
  font-size: 10pt;
}

.te-node-capture {
  color: #c7e2ff;
}

.te-node-identifier {
  color: #c7ffd0;
}

.te-node-split {
  color: #ffccc7;
}

.te-node-table {
  color: #ffe3c7;
}

.te-node-column {
  color: #ffe3c7;
}

.te-node-scan {
  color: #ffecb9;
}

.te-captureZone-1 {
  background-color: rgba(241, 245, 158, 0.4);
  border: solid 3px #5580d1;
}

.te-splitZone-2 {
  background-color: rgba(180, 82, 82, 0.3);
  border: none;
}

.te-captureZone-3 {
  background-color: rgba(241, 245, 158, 0.2);
  border: dashed 3px #5581d1a9;
}

.te-splitZone-1 {
  background-color: rgba(141, 36, 36, 0.4);
  border: solid 3px #bf3939;
}

.te-tableZone-2 {
  background-color: rgba(138, 48, 48, 0);
  border: none;
}

.te-tableZone-1 {
  background-color: rgba(231, 191, 81, 0);
  border: solid 2px #f9ca38;
  margin: -2px;
}

.te-table-gridHeader {
  background-color: rgba(249, 202, 56, 0.7);
  padding: 5px;
  font-size: 10pt;
  cursor: nesw-resize;
  font-weight: 600;
  margin-left: 5px;
}

.te-table-hidden {
  display: none;
}

.te-identifierZone-2 {
  background-color: rgba(108, 193, 101, 0.3);
  border: none;
}

.te-identifierZone-1 {
  background-color: rgba(89, 180, 82, 0.4);
  border: solid 3px #489941;
}

.te-captureZone-2 {
  background-color: rgba(85, 128, 209, 0.3);
  border: none;
}

.te-scanZone-1 {
  background-color: rgba(254, 255, 161, 0.4);
  border: solid 3px #a8a97a;
}

.te-scanZone-2 {
  background-color: rgba(234, 235, 171, 0.301);
  border: dashed 1px #afaf37;
}

.te-readValue {
  background-color: #2b4e7a;
  color: #fff;
  padding: 4px;
  font-size: 12pt;
  font-weight: 600;
}

.te-identifierValue {
  background-color: #3c8f2a;
  color: #fff;
  padding: 4px;
  font-size: 12pt;
  font-weight: 600;
}

.te-splitValue {
  background-color: #8d2424;
  color: #fff;
  padding: 4px;
  font-size: 12pt;
  font-weight: 600;
}

.te-childZone {
  background-color: rgba(143, 144, 103, 0.5);
  border: solid 3px #414229;
}

.te-ocrZone {
  background-color: rgba(255, 199, 199, 0.3);
  border: dotted 2px #ffadad;
}

.te-textZone {
  background-color: rgba(53, 193, 46, 0.249);
  border: dotted 2px #adffb4;
}

.te-ocrText {
  background-color: rgba(210, 0, 0);
  font-size: 10pt;
  z-index: 200;
  color: #fff;
}

.te-textText {
  background-color: rgb(22, 145, 5);
  font-size: 10pt;
  z-index: 200;
  color: #fff;
}

.te-column {
  background-color: rgba(255, 249, 167, 0.1);
  border: dashed 2px #f9ca38;
}

.te-selected-column {
  background-color: rgba(255, 249, 167, 0.5);
  border: dashed 2px #48da34;
}

.te-row {
  background-color: rgba(49, 100, 173, 0.2);
  border-bottom: solid 2px #3164ad;
}

div.te-row:hover {
  background-color: red;
}

.te-row .te-cellText {
  /*visibility: hidden;*/
  background-color: rgba(49, 100, 173, 0.9);
  color: #fff;
  text-align: center;
  font-size: 10pt;
  border-radius: 4px;
  padding: 2px;
}

.te-cellText:hover {
  background-color: red !important;
}

.te-formtype-open-link {
  color: #8de3ec;
  cursor: pointer;
  text-decoration: none;
}

.te-formtype-open-link2 {
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
}

.te-template-link {
  color: #5680c3;
  cursor: pointer;
  text-decoration: none;
  margin-left: 10px;
}

.te-template-link a {
  color: #5680c3;
  text-decoration: none;
  cursor: pointer;
}
