.App {
  height: 100%;
}


  $base-color1: rgb(192, 226, 227);
  $base-color2: #48aeb0;
  $headerLinkColor: rgba(0, 0, 0, 0.55);
  $quickSearchBack: #dcfcfe;
  $quickSearchText: #000;


html,
body {
  height: 100%;
}

#root {
  height: 100%;
}

#webUploadLink {
  /*color: $headerLinkColor;*/
  text-decoration: none;
}

.App-logo {
  //height: 50vmin;
  pointer-events: none;
  max-width: 400px;
  //max-height: 90px;
  margin-bottom: 10px;
}

.app-link {
  color: rgb(38, 43, 66);
  text-decoration: none;
}

.aiMenuIcon {
  max-width: 22px;
  max-height: 22px;
  margin-left: 6px;
}

.aiMenuIcon2 {
  max-width: 45px;
  max-height: 45px;
  margin-left: 6px;
}

.link {
  color: #2974c3;
  cursor: pointer;
  text-decoration: underline;
}
.nav-icon {
  color: $headerLinkColor;
}

.nav-link.nav-sm {
  height: 30px;
  padding: 2px 8px 2px 8px;
  color: $headerLinkColor;
}

.nav-link2.nav-sm {
  height: 30px;
  padding: 3px 8px 3px 8px;
  border-radius: 4px;
  color: #fff;
}

.nav-link3.nav-sm {
  height: 30px;
  padding: 3px 8px 3px 8px;
  border-radius: 4px;
  color: #fff;
}

.nav-pills .nav-link.nav-sm.active {
  background-color: rgb(60, 180, 194);
}

.nav-pills .nav-link2.nav-sm {
  background-color: #ac86c1;
}

.nav-pills .nav-link3.nav-sm {
  background-color: #767676;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*font-size: calc(10px + 2vmin);*/
}

.Light {
  $primary: #000;
  .navbar {
    background-color: $base-color1;
    border-bottom: solid 1px $base-color2;

    color: #fff;
  }
  .td-background {
    background-color: #efefef;
  }
  .App {
    background-color: white;
  }
}
.Dark {
  .navbar {
    background-color: #465c5d;
    border-bottom: solid 1px #687a7c;
    color: #fff;
  }
  .td-background {
    background-color: #404040;
  }
  .card {
    border: 1px solid #2e2e2e;
  }
  .card-header {
    background-color: #2e2e2e;
    color: #fff;
  }
  .card-body {
    background-color: #535353;
    color: #fff;
  }
  .App {
    background-color: #4e4e4e;
  }
}

.navbar {
  /*height: 45px; */
  padding-left: 20px;
  padding-right: 20px;
}

.tdSideNavbar {
  position: absolute;
  background-color: rgba(20, 20, 20, 0.95);
  height: 93%;
  width: 320px;
  color: #fff;
  overflow-y: auto;
  z-index: 100;
}

.navHeader {
  font-size: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  padding-bottom: 4px;
  padding-top: 4px;
  margin-left: 9px;
  cursor: pointer;
}

.navSubHeader {
  font-size: 16px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  padding: 0px 9px 9px 20px;
  cursor: pointer;
}

.navHeader a {
  text-decoration: none;
  color: #fff;
}
.navHeader a:hover {
  color: rgb(111, 200, 210);
}

.navSubHeader a {
  text-decoration: none;
  color: #fff;
}
.navSubHeader a:hover {
  color: rgb(161, 221, 227);
}

.navHeader:hover {
  color: rgb(162, 244, 255);
}

.navLabel {
  font-size: 16px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 9px 9px 9px 12px;
  font-weight: 700;
}

.navSubHeader:hover {
  color: rgb(162, 244, 255);
}


.td-navbar .navbar-brand {
  background-color: red;
}

.td-navButton:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.adc-row {
  height: 26px;
}

.td-navButton {
  border: none;
}

.td-search-bar {
  margin-right: 8px;
  position: relative;
}

.td-appLink {
  color: rgb(49, 52, 85);
  text-decoration: underline;
  cursor: pointer;
}

.td-qsearch-container {
  border: solid 1px $base-color2;
  height: 40px;
  border-radius: 15px;
  padding: 5px 15px 5px 15px;
  background: $quickSearchBack;
}

.td-search-container {
  border: solid 1px $base-color2;
  height: 40px;
  border-radius: 15px;
  padding: 5px 15px 5px 15px;
  width: 50%;
  background: $quickSearchBack;
  //background: #DCFCFE;
}

.td-search-container2 {
  border: solid 1px $base-color2;
  height: 40px;
  border-radius: 15px;
  padding: 5px 15px 5px 15px;
  width: 200px;
  background: $quickSearchBack;
  //background: #DCFCFE;
}

#tdSearchTxt {
  margin-right: 0px;
  width: 100%;
  border: none;
  //background: #DCFCFE;
}

.td-search-input {
  margin-right: 18px;
  width: 280px;
  border: none;
  color: $quickSearchText;
  background: $quickSearchBack;
}

#tdqSearchTxt:focus {
  border: none;
  outline: none;
}

#tdSearchTxt:focus {
  border: none;
  outline: none;
}

.td-dialogue-container {
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  z-index: 1000;
}

.td-dialogue.card {
  border: solid 1px #195f63;
}

.td-dialogue-label {
  font-weight: 650;
}



.table-header {
  background: -moz-linear-gradient(
    top,
    #87e0fd 0%,
    #51e8dd 40%,
    #06cece 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #87e0fd 0%,
    #51e8dd 40%,
    #06cece 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #87e0fd 0%,
    #51e8dd 40%,
    #06cece 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

}

.table-header .btn-close {
  float: right;
}

.td-dialogue .card-header {
  border-radius: 10px;
  background: -moz-linear-gradient(
    top,
    #87e0fd 0%,
    #51e8dd 40%,
    #06cece 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #87e0fd 0%,
    #51e8dd 40%,
    #06cece 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #87e0fd 0%,
    #51e8dd 40%,
    #06cece 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.td-error-dialogue .card-header {
  border-radius: 10px;
  background: rgb(135,24,24);
  background: linear-gradient(0deg, rgba(135,24,24,1) 0%, rgba(219,48,48,1) 51%, rgba(135,24,24,1) 100%);
  color: #fff;
}

.td-dialogue .card-header .btn-close {
  float: right;
}

.td-error-dialogue .card-header .btn-close {
  float: right;
}
.App-link {
  color: #61dafb;
}

.App-version {
  color: rgb(61, 107, 109);
  font-size: 12pt;
  font-weight: 600;
  margin-bottom: 15px;
}

.login-form {
  width: 400px;

  color: #000;
}

.login-input {
  margin-bottom: 16px;
}

.login-form .login-button {
  background-color: rgb(60, 180, 194);
  color: #fff;
  width: 100%;
  margin-bottom: 5px;
}

.login-button:hover {
  color: #fff;
}

.msbutton {
  border: 1px solid #8c8c8c;
  overflow: auto;
  background-color: #fff;
  padding-left: 100px;
  align-content: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 15px;
  font-weight: 600;
  margin-top: 6px;
  color: #5e5e5e;
}
.msbutton:hover {
  background-color: rgb(179, 206, 255);
  cursor: pointer;
  color: #363636;
}

.card-header h4 {
  padding: 0;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#tdGrid {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  border: 1px solid black;
}

#tdGrid td,
#tdGrid th {
  border: 1px solid #ddd;
  padding: 8px;
}

.tdGrid {
  border-radius: 3px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  border: 1px solid rgb(219, 219, 219);
}

.tdGrid th {
  background-color: rgba(0, 0, 0, 0.03);
  font-size: 14px;
  padding: 8px;
}

.tdGrid td {
  background-color: #fff;
  padding: 8px;
  word-wrap: break-word;
}

.link {
  fill: none;
  stroke: #ccc;
  stroke-width: 1.5px;
}

.tdGrid .link {
  color: #2974c3;
  cursor: pointer;
  text-decoration: underline;
}

.tdDialog .link {
  color: #2974c3;
  cursor: pointer;
  text-decoration: underline;
}

.tdDialog {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 250px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.1); /* Black w/ opacity */
}

.tdDialogContent {
  margin: auto;
  width: 40%;
}

.sidebarLayout {
  background-color: #fff;
}

.sidebar-container {
  background-color: #464646;
  width: 245px;
}

.sidebar-container2 {
  background-color: #464646;
  width: 274px;
}

.sidebar-link {
  text-decoration: none;
  color: #4183c4;
}

.sidebar-link2 {
  text-decoration: none;
  color: #4183c4;
  font-size: 10pt;
}

.list-group-item {
  cursor: pointer;
  white-space: normal;

  word-wrap: break-word;
  display: block;
  background-color: red;
}

.fieldFilterGroup {
  background-color: #f0f0f0;
  width: 400px;
  border: solid 1px #ccc;
}
.fieldFilterGroup th {
  background-color: #e6e6e6;
  padding: 4px;
  font-size: 13px;
  font-weight: 500;
  border: solid 1px #ccc;
  border-bottom: solid 1px #888;
}

.fieldFilterGroup td {
  padding: 4px;
}

.fieldGroupSeparator {
  width: 400px;
  text-align: center;
  padding: 12px;
}

.license-header {
  font-size: 10pt;
  font-weight: 700;
  color: #545454;
}
.license-header2 {
  font-size: 9pt;
  font-weight: 400;
  color: #545454;
  padding-left: 10px;
}

.license-item {
  font-size: 10pt;
  font-weight: 400;
  margin-left: 13px;
  margin-bottom: 12px;
}

.license-count {
  font-size: 11pt;
  text-align: right;
  font-weight: 500;
  color: #2974c3;
}

.license-count2 {
  font-size: 10pt;
  text-align: right;
  padding-right: 10px;
}

#ddLicenseLevel.llbtn-sysadmin {
  background-color: #cd5c5c;
  border: solid 1px #934343;
}

#ddLicenseLevel.llbtn-viewer {
  background-color: #f1d951;
  border: solid 1px #b0ae5b;
  color: #000;
}

#ddLicenseLevel.llbtn-approver {
  background-color: #528ccd;
  border: solid 1px #4474a9;
}

#ddLicenseLevel.llbtn-busadmin {
  background-color: #5cc951;
  border: solid 1px #44a949;
}

.wf-StepTable tbody {
  border-left: solid 1px #cccccc;
  border-right: solid 1px #cccccc;
  background-color: #f8f8f8;
  padding: 5px;
}

.wf-StepTable th {
  border-bottom: solid 1px #464646;
}

.wf-StepTable td {
  border-bottom: solid 1px #cccccc;
  margin-right: 4px;
  margin-top: 4px;
  padding: 6px;
  height: 30px;
}

.wf-StepTable .borderUnder td {
  border-bottom: solid 4px #175cc5;
  margin-right: 4px;
  margin-top: 0px;
  padding: 6px;
  height: 30px;
}
.wf-StepTable .borderOver td {
  border-top: solid 4px #175cc5;
  border-bottom: solid 1px #cccccc;
  margin-right: 4px;
  margin-top: 0px;
  padding: 6px;
  height: 30px;
}

.wf-StepTable .dragging td {
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(210,242,255,1) 50%, rgba(255,255,255,1) 100%);
  border-bottom: solid 1px #cccccc;
  margin-right: 4px;
  margin-top: 0px;
  padding: 6px;
  height: 30px;
}

.btn-circle {
  border-radius: 20px !important;
}

/* Toasts */
.toast.toast-info {
  background-color: rgba(132, 199, 254, 0.8);
  border-radius: 10px;
  border: solid 1px rgb(132, 199, 254);
  z-index: 700;
}
.toast.toast-error {
  background-color: #c51d1d;
  border-radius: 10px;
  border: solid 1px rgb(241, 78, 78);
  color: #fff;
  z-index: 700;
}

.toast.toast-error span.toast-message {
  color: #000;
}

.toast.toast-success {
  background-color: rgba(132, 255, 123, 1);
  border-radius: 10px;
  z-index: 900;
}

.toast-header.td-toast-header {
  background-color: rgba(255, 255, 255, 0.5);
}

div.td-sel-list {
  height: 150px;
  border: solid 1px #888;
  border-radius: 6px;
  overflow: auto;
}

div.td-sel-list ul {
  list-style-type: none;
  padding-left: 5px;
  padding-top: 4px;
}

div.td-sel-list li {
  cursor: pointer;
  padding-left: 8px;
}
div.td-sel-list li:hover {
  cursor: pointer;
  font-weight: 700;
  background: #dceffe;
}

.de-format {
  color: rgb(130, 130, 130);
  margin-left: 6px;
}

.de-loadingScreen {
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 60;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4a4a4a;
  z-index: 1000;
}

div.ft-search-colheader {
  background: rgb(60, 180, 194);
  padding: 4px 6px 4px 6px;
  border-radius: 4px;
  color: #fff;
}

.drop-container {
  position: relative;
  background: #ffffff;
  box-shadow: 1px 1px 14px rgba(0, 0, 0, 0.3);
  border: solid rgb(167, 167, 167) 1px;
  padding-right: 0;
  padding-left: 0;
  border-radius: 0.28571429rem;
}

.drop-content {
  text-align: center;
  padding: 10px;
  cursor: pointer;
}

.drop-header {
  font-weight: 700;
  font-size: 1.5em;
}

.drop-subheader {
  margin-bottom: 0;
  cursor: pointer;
  color: #929292;
}

.dropdown-content-bulk-menu {
  left: -40px;
  position: absolute;
  background-color: #f1f1f1;
  top: 45px;
  min-width: 240px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.dropdown-content-bulk-menu div {
  color: black;
  padding: 10px 14px;
  text-decoration: none;
  display: block;
}
.dropdown-content-bulk-menu hr {
  margin: 0px 2px;
}

.dropdown-content {
  position: absolute;
  background-color: #f1f1f1;
  top: 45px;
  min-width: 300px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content div {
  color: black;
  padding: 10px 14px;
  text-decoration: none;
  display: block;
}
.dropdown-content hr {
  margin: 0px 2px;
}

.dropdown-menuitem {
  cursor: pointer;
}

.dropdown-menuitem2 {
  color: #AAAAAA !important;
}

.dropdown-menuitem:hover {
  background-color: #dedede;
}

.document-menu-dropdown {
  position: absolute;
  top: 46px;
  background-color: #f0f0f0;
  border: solid 1px #919191;
  padding: 16px;
  border-radius: 6px;
  margin-left: -200px;
  min-width: 350px;
  filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
  z-index: 200;
}

.document-menu-dropdown hr {
  margin-top: 3px;
  margin-bottom: 3px;
}

.docMenu-menuItem {
  font-size: 9pt;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 3px;
  padding: 4px;
  color: #848484;
}

.docMenu-menuItem:hover {
  border-radius: 8px;
  color: #000;
  background-color: #d6d6d6;
}

.docMenu-heading-1 {
  font-size: 10pt;
  font-weight: 700;
}

.docMenu-value-1 {
  font-size: 10pt;
  color: #848484;
}

.dropdown-content .attchMenu-attachment {
  background-color: #fff;
  margin: 8px;
  border: 1px solid #777;
  padding: 4px 4px;
  border-radius: 3px;
}

.dropdown-content .stampMenu-stamp {
  background-color: #fff;
  margin: 8px;
  border: 1px solid #777;
  padding: 4px 4px;
  border-radius: 3px;
}

.dropdown-content .attchMenu-filename {
  font-weight: 700;
  font-style: italic;
  padding: 0px 0px;
  margin-left: 6px;
}

.dropdown-content .stampMenu-groupName {
  font-weight: 700;
  font-style: italic;
  padding: 0px 0px;
  margin-left: 6px;
}

.dropdown-content .stampMenu-stampLineApprove {
  font-size: 12pt;
  color: rgb(32, 148, 76);
  padding: 0px 0px;
  margin-left: 12px;
}

.dropdown-content .stampMenu-stampLineReject {
  font-size: 12pt;
  color: rgb(148, 32, 32);
  padding: 0px 0px;
  margin-left: 12px;
}

.dropdown-content .stampMenu-stampLinePending {
  font-size: 12pt;
  color: rgb(59, 59, 59);
  padding: 0px 0px;
  margin-left: 12px;
}

.dropdown-content .attchMenu-attachBy {
  font-size: 9pt;
  color: #777;
  padding: 0px 0px;
  margin-left: 12px;
}

.dropdown-content .attchMenu-downloadDelete {
  font-size: 12pt;
  padding: 0px 0px;
  margin-left: 6px;
}

.stamp-position-icon {
  background-color: rgb(49, 52, 85);
  position: absolute;

  z-index: 100;
  color: #fff;
  border-radius: 4px;
  padding-left: 6px;
  padding-right: 6px;
}

.stamp-position-icon.stamp-transparent {
  background-color: rgba(49, 52, 85, 0.5);
}

.stamp-position-large {
  width: 70px;
  height: 40px;
}

.stamp-position-medium {
  width: 50px;
  height: 35px;
}

.stamp-position-small {
  width: 30px;
  height: 25px;
}

.stamp-index {
  background-color: rgb(49, 52, 85);
  color: #fff;
  border-radius: 4px;
  margin-right: 6px;
  padding-left: 4px;
  padding-right: 4px;
}

.selected {
  background-color: #8edfed;
  border-radius: 6px;
  padding: 4px;
}

.queueAssignOther {
  padding: 7px 12px;
  margin: 7px 0px 7px 7px;
  font-size: 12px;
  background-color: #18989e;
  color: #fff;
  border-radius: 12px;
}

.queueAssignApproved {
  padding: 7px 12px;
  margin: 7px 0px 7px 7px;
  font-size: 12px;
  font-weight: 700;
  background-color: #c2c2c2;
  color: #555;
  border-radius: 6px;
}

.queueAssignDelete {
  padding: 7px 12px;
  margin: 7px 0px 7px 7px;
  font-size: 12px;
  font-weight: 700;
  background-color: #cdcdcd;
  color: #535353;
  border-radius: 12px;
}

.queueAssignMe {
  padding: 7px 12px;
  margin: 7px 0px 7px 7px;
  font-size: 12px;
  font-weight: 700;
  background-color: #195ab2;
  color: #fff;
  border-radius: 6px;
}

.queueAssignNew {
  padding: 7px 12px;
  margin: 7px 0px 7px 7px;
  font-size: 12px;
  background-color: #904caf;
  color: #fff;
  border-radius: 12px;
}

.propLabel {
  font-weight: 600;
  font-size: 14pt;
}
.propLabel2 {
  font-weight: 500;
  font-size: 12pt;
}

.propValue {
  font-size: 14pt;
  margin-left: 8px;
}

.propValue2 {
  font-size: 12pt;
  margin-left: 8px;
}

.td-edit-label1 {
  font-size: 10pt;
}

.template-thumbnail {
  margin-top: 18px;
  padding: 4px;
  border: solid 3px #333;
  cursor: pointer;
}

.template-thumbnail:hover {
  //border: solid 3px #f3fe9c;
  //border-radius: 12px;
}

.template-image:hover
{
  border: solid 4px #fffcab;

}

.teplate-tableResult tbody {
  border-left: solid 1px #21bed1;
}

.teplate-tableResult th {
  font-weight: 400;
  padding: 2px;
  padding-right: 4px;
  border-bottom: solid 4px #1a8693;
}

.teplate-tableResult td {
  padding: 2px;
  padding-right: 6px;
  border-bottom: solid 1px #21bed1;
  border-right: solid 1px #21bed1;
}

div.rolePermissionCheck {
  text-align: center;
  width: 45px;
  max-width: 45px;
  margin-top: 8px;
  border: solid 1px #ccc;
  margin-top: 0px;
  /*background-color:rgb(60, 180, 194);*/
}

div.rolePermissionCheck input {
  width: 18px;
  height: 18px;
  margin-top: 4px;
}

div.rolePermissionLabel {
  text-align: center;
  vertical-align: center;
  display: table;
  max-width: 45px;
  width: 45px;
  border-right: solid 1px #777;
  border-top: solid 1px #777;
  border-bottom: solid 1px #777;
}

div.condition-group {
  background: linear-gradient(to bottom, #b2dce8 0%, #c7e9ed 100%);
  border: solid 1px #29a5a5;
  border-radius: 8px;
  margin-right: 4px;
}

div.assignment-group {
  background: linear-gradient(to bottom, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
  border: solid 1px #989e91;
  border-radius: 8px;
}

div.edit-group {
  border: solid 1px #ccc;
  border-radius: 6px;
}

h2.sys-title {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #fff;
}

div.sys-link a {
  color: #fff;
  text-decoration: none;
  font-size: 12pt;
  font-weight: 400;
}

div.sys-link-group {
  font-size: 16pt;
  padding: 10px;
  font-weight: 500;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #fff;
  margin-left: 80px;
}

div.sys-link-list {
  margin-top: 8px;
}

div.sys-link {
  text-align: center;
  padding-top: 10px;
  min-width: 100px;

  /*background-color: red; */
  border-radius: 10px;
  margin-right: 16px;
  padding: 6px;
}

div.sys-link :hover {
  text-decoration: underline;
}

div.document-dropdown-container {
  position: relative;
}

ul.document-dropdown-list {
  position: absolute;
  top: 37px;
  padding: 2px;
  border: 1px solid #ccc;
  background: #fff;
  z-index: 250;
  border-radius: 6px;
  max-height: 200px;
}

ul.document-dropdown-list li {
  list-style-type: none;
  padding: 6px;
  cursor: pointer;
}

ul.document-dropdown-list li.active {
  background-color: #3164ad;
  color: #fff;
  border-radius: 3px;
}

ul.l2 {
  max-height: '200px';
  overflow: auto;
}

div.document-grid-container {
  margin-top: 12px;
  /*border-top: solid 1px #5D6D7E;*/
  /*border-left: solid 1px #5d6d7e;
  border-bottom: solid 1px #5d6d7e;*/
  overflow-y: hidden;
  overflow-x: auto;
  padding-right: 0;
  margin-right: 0px;
}

div.document-grid-rows {
  /*background-color: #e7e7e7;*/
  overflow-x: hidden;
  overflow-y: auto;
}

div.document-grid-header {
  background: #d6dbdf;
  width: 800px;
  height: 200px;
}

td.document-grider-header-cell1 {
  padding: 4px;
  border-top: solid 1px #5d6d7e;
  border-right: solid 1px #85929e;
  border-bottom: solid 1px #85929e;
  border-left: solid 1px #5d6d7e;
  background: #d6dbdf;
}

td.document-grider-header-cell2 {
  background: #d6dbdf;
  border-top: solid 1px #5d6d7e;
  padding: 4px;
  padding-top: 16px;
  border-bottom: solid 1px #85929e;
  font-weight: 500;
  font-size: 10pt;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  height: 40px;
}

td.document-grider-header-left {
  background: #d6dbdf;
  border-top: solid 1px #5d6d7e;
  border-bottom: solid 1px #85929e;
  cursor: ew-resize;
  height: 40px;
  width: 8px;
  min-width: 8px;
}

td.document-grider-header-right {
  background: #cacfd3;
  border-top: solid 1px #5d6d7e;
  border-right: solid 1px #85929e;
  border-bottom: solid 1px #85929e;
  height: 40px;
  cursor: ew-resize;
  width: 8px;
  min-width: 8px;
}

td.document-grider-data-cell1 {
  border-right: solid 1px #85929e;
  border-bottom: solid 1px #85929e;
  border-left: solid 1px #5d6d7e;
}

td.new-rec {
  background-color: #b0dbd0;
}

td.document-grider-data-cell2 {
  padding: 1px;
  border-right: solid 1px #85929e;
  border-bottom: solid 1px #85929e;
  font-weight: 500;
  font-size: 10pt;
}

div.document-grid-pageNav {
  border: solid 1px #939393;
  border-radius: 8px;
  background-color: #faf5e4;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 8px;
  margin-top: 3px;
  width: 230px;
}

td.document-grid-navBtn-disabled {
  color: #cacaca;
  text-align: center;
  width: 40px;
}

td.document-grid-navBtn {
  cursor: pointer;
  text-align: center;
  width: 40px;
}

td.document-grid-navBtn :hover {
  color: #52d22d;
}

li.wf-reqField-item {
  background-color: #e8ffff;
  list-style-type: none;
  padding: 4px;
  padding-left: 8px;
  border: solid 1px #bbb;
  border-radius: 4px;
}

li.wf-assignee-item {
  background-color: #eae2d5;
  list-style-type: none;
  padding: 4px;
  padding-left: 8px;
  border: solid 1px #bbb;
  border-radius: 4px;
}

div.mini-label {
  font-size: 9pt;
}

table.audit-table {
  margin-top: 12px;
  border: solid 1px #bbb;
  max-width: 90%;
  min-width: 90%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

table.audit-table th {
  background-color: #f9fafb;
  font-weight: 600;
  font-size: 10pt;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 4px;
  border-bottom: solid 2px #656565;
  min-width: 45px;
}

table.audit-table td {
  font-size: 10pt;
  padding: 2px;
  padding-left: 4px;
  border-bottom: solid 1px #999;
  border-right: solid 1px #999;
  overflow: hidden;
  //text-overflow: ellipsis;
  max-width: 800px;
}

.errorField {
  position: relative;
  /*display: inline-block;*/
}

/* Tooltip text */
.errorField .tooltiptext {
  visibility: hidden;
  width: 200px;
  font-size: 10pt;
  background-color: rgb(247, 247, 247);
  color: rgb(0, 0, 0);
  text-align: left;
  padding: 5px;
  border: solid 1px rgb(148, 148, 148);
  border-radius: 6px;

  top: 100%;
  left: 50%;
  margin-left: -150px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 600;
}

/* Show the tooltip text when you mouse over the tooltip container */
.errorField:hover .tooltiptext {
  visibility: visible;
}

input.fieldEditError {
  background-color: #ffd8d8;
  border: solid 1px #da3535;
}

select.fieldEditError {
  background-color: #ffd8d8;
  border: solid 1px #da3535;
}

.autocomplete {
  /*the container must be positioned relative:*/
  position: relative;
  display: inline-block;
  width: 100%;
}

.autocomplete2 {
  /*the container must be positioned relative:*/

  position: relative;
}

.autocomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;
}

.docAssignUserItem {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;
  border-radius: 9px;
  box-shadow: 5px 5px 8px #888888;
}

.moveAssignUserItem {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;
  border-radius: 3px;
  box-shadow: 5px 5px 8px #888888;
}

.docAssignItem {
  background-color: #fff;
  padding: 8px;
  border-bottom: 1px solid #d4d4d4;
}
.docAssignItem:hover {
  background-color: rgb(210, 214, 252);

  cursor: pointer;
}

.docAssignItem .docAssignSubHeader {
  font-weight: 400;
  padding: 0;
  margin: 0;
  line-height: 1em;
  font-size: 10pt;
  color: rgba(0, 0, 0, 0.6);
}

.autocomplete-items2 {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;
}
.autocomplete-items div {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
}
.autocomplete-items div:hover {
  /*when hovering an item:*/
  background-color: #e9e9e9;
}
.autocomplete-active {
  /*when navigating through the items using the arrow keys:*/
  background-color: DodgerBlue !important;
  color: #ffffff;
}

.autocomplete-items2 {
  padding: 10px;
  background-color: #e9e9e9;
}

.autocomplete-items2 div {
  cursor: pointer;
  background-color: #fff;
}
.autocomplete-items2 div:hover {
  /*when hovering an item:*/
  background-color: #e9e9e9;
}
.autocomplete-active2 {
  /*when navigating through the items using the arrow keys:*/
  background-color: DodgerBlue !important;
  color: #ffffff;
}

.bg-imageRev {
  background-color: #ae4bcf;
}

.imageReview-image {
  height: 120px;
  width: 100px;
  border: 1px solid #444;
  background-color: #fff;
  cursor: pointer;
  box-shadow: 4px 4px 6px #aaa;
}

.imageReview-existing {
  border: 1px solid #121212;
  margin: 8px;
  border-radius: 8px;
  background-color: #dbdbdb;
  box-shadow: 4px 4px 6px #aaa;
}

.imageReview-new {
  border: 1px solid #729475;
  margin: 8px;
  border-radius: 8px;
  background-color: #cce0b5;
  box-shadow: 4px 4px 6px #aaa;
  overflow-y: scroll;
  min-height: 60px;
}

.imageReview-deleted {
  border: 1px solid #121212;
  margin: 8px;
  border-radius: 8px;
  background-color: #d88d8d;
  box-shadow: 4px 4px 6px #aaa;
}

.imageReview-documentTitle {
  margin-top: 8px;
  margin-left: 8px;
  font-weight: 400;
  font-size: 12pt;
  color: rgb(71, 71, 71);
}

.imageReview-imageContainer {
  padding-left: 20px;
  padding-right: 8px;
  padding-bottom: 12px;
  color: rgb(71, 71, 71);
}

.imageReview-existing {
  overflow-y: scroll;
  min-height: 60px;
}

.coolScroll3::-webkit-scrollbar {
  width: 20px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.1);
  padding-left: 2px;
  padding-right: 2px;
}

.coolScroll3::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
  min-height: 10px;
}

.imageReview-createDoc {
  background-color: rgb(235, 255, 237);
  border-radius: 7px;
  border: solid 1px #888;
  margin: 8px;
  text-align: center;
  border-top: solid 3px #065012;
  font-size: 26pt;
  font-weight: 800;
  color: #065012;
  box-shadow: 4px 4px 6px #aaa;
  margin-top: 10px;
  width: 60px;
}

.imageReview-createDoc-hover {
  background-color: rgb(156, 231, 163);
  border-radius: 7px;
  border: solid 1px #888;
  margin: 8px;
  text-align: center;
  border-top: solid 3px #065012;
  font-size: 26pt;
  font-weight: 800;
  color: #065012;
  box-shadow: 4px 4px 6px #aaa;
  margin-top: 10px;
  width: 60px;
}

.imageReview-dropbox {
  margin: 6px;
  border: dashed 2px #404040;
  min-height: 20px;
  background-color: rgba(255, 255, 255, 0.6);
  display: none;
}

.imageReview-rotate90 {
  transform: rotate(90deg);
}

.imageReview-rotate180 {
  transform: rotate(180deg);
}

.imageReview-rotate270 {
  transform: rotate(270deg);
}

.userNote-container {
  background-color: #fff;
  border: 1px solid rgb(90, 90, 90);
  padding: 10px;
  color: rgb(90, 90, 90);
  border-radius: 4px;
}

.userNote-note {
  border: 1px solid rgb(90, 90, 90);
  border-radius: 8px;
  background-color: #f1f2b8;
  padding: 6px;
  margin-top: 10px;

  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.userNote-noteBody {
  font-size: 11pt;
  color: #000;
}

.userNote-noteFooter {
  font-size: 10pt;
  margin-top: 4px;
}

.role-permission-selection {
  background-color: #2e86c1;
  border-radius: 8px;
  color: #fff;
  padding: 3px;
  padding-left: 5px;
  padding-right: 5px;
}

.relatedDocsList {
  list-style-type: none;
  margin: 0;
  padding: 0;

  background-color: #eee;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: none;
  overflow-y: auto;
}

.relatedDocsList li {
  float: none;

  font-size: 15px;
  padding: 9px;
  padding-left: 12px;
  padding-right: 12px;
  border-right: solid 1px #999;
  border-bottom: solid 1px #999;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 400;
  color: #222;
  cursor: pointer;
  white-space: nowrap;
  font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
}

.relatedDocsList li.relatedHeader {
  background-color: #ddd;
}

.relatedDocsList li.relatedLink:hover {
  background-color: #ddd;
}

.relatedLink {
  position: relative;
  display: inline-block;
}

.relatedLink .relatedTip {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: left;
  padding: 5px 6px;
  border-radius: 6px;
  position: absolute;
  z-index: 900;
}

.relatedLink:hover .relatedTip {
  visibility: visible;
}

.relatedTip {
  width: 170px;
  background-color: black;
  color: #fff;
  text-align: left;
  padding: 5px 6px;
  border-radius: 6px;
  position: absolute;
  z-index: 900;
}

.td-detail-desc {
  font-size: small;
  color: $base-color2;
  margin-bottom: 8px;
}

.activeExtender {
  background-color: #c0e3d2;
  cursor: pointer;
}

.workflowStep-Start {
  background-color: #aad4aa;
  border: solid 1px #777;
  border-radius: 10px;
  padding: 12px;
}

.workflowStep-User {
  background-color: #fffdcb;
  border: solid 1px #777;
  border-radius: 10px;
  padding: 12px;
}

.workflowStep-Auto {
  background-color: #dfdfdf;
  border: solid 1px #777;
  border-radius: 10px;
  padding: 12px;
}

.workflowStep-InActive {
  background-color: #4c4c4c;
  color: #fff;
  border: solid 1px #777;
  border-radius: 10px;
  padding: 10px;
}

.purpleCheck {
  color: #ad4ff6;
  background-color: #fff;
  font-size: 14pt;
  cursor: pointer;
  margin-left: 4px;
}

.redCheck {
  color: #f64f4f;
  background-color: #fff;
  font-size: 14pt;
  cursor: pointer;
}

.greenCheck {
  color: #4cbe75;
  background-color: #fff;
  font-size: 14pt;
  cursor: pointer;
  margin-left: 4px;
}

.highlight {
  background-color: #FFFF00
}

.nav-button.active {
  background-color:rgb(55, 55, 221);
  color: #fff !important;
  border-radius: 10px;
  margin-right:4px;
  cursor:default;
}

.nav-button {
  margin-right:4px;
  cursor:pointer;
}
