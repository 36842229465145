.list-group.active-documents .list-group-item {
  background-color: rgba(0, 0, 0, 0);
  color: #fff;
  /*height: 22pt;*/
  padding: 3px;
}

.orgUnit-icon {
  color: #efda62;
}

.redo-icon {
  color: #4c70bd;
}

.redo-icon:hover {
  color: #6e95ea;
}

.businessProcess-icon {
  color: #b023dd;
}

table.adc-table {
  border-right: solid 1px#999;
}

table.adc-table tr.hasHover {
  background: #badcec;
}

table.adc-table th {
  padding: 5px;
  background: rgb(221, 221, 221);
  background: linear-gradient(
    0deg,
    rgba(221, 221, 221, 1) 0%,
    rgba(246, 246, 246, 1) 100%
  );
  font-size: 10pt;
  border-bottom: solid 1px#999;
}

table.adc-table td {
  min-width: 150px;
  border-bottom: solid 1px #ccc;
  padding: 0;
  border-right: solid 1px #ccc;
  padding-left: 4px;
}

table.adc-table td.td-large {
  padding-left: 4px;
}

table.adc-table td.td-small {
  height: 20px;
  padding-left: 2px;
}

table.adc-table td.td-large td.col-one {
  font-weight: 700;
  height: 30px;
}

table.adc-table td.td-small,
td.col-one {
  font-weight: 450;
}

table.adc-table td.col-one {
  cursor: pointer;
  text-decoration: none;
  color: #000;
}

table.adc-table div.row {
  margin-left: 0;
  vertical-align: middle;
  max-height: 29px;
}

table.adc-table tr.adc-row td.td-large {
  height: 31px;
}

table.adc-table td.td-small div.row {
  height: 100%;
}

table.adc-table td.col-one {
  padding: 0;
  padding-left: 0px;
}

.adc-col-header {
  position: absolute;
  background: rgb(219, 219, 219);
  background: linear-gradient(
    0deg,
    rgba(219, 219, 219, 1) 0%,
    rgba(235, 235, 235, 1) 100%
  );
  border-bottom: 1px solid #888;
  border-right: 1px solid #aaa;
  font-weight: 600;
  overflow: none;
  text-overflow: ellipsis;
  padding: 8px;
}

.adc-col-header2 {
  background: linear-gradient(
    0deg,
    rgba(219, 219, 219, 1) 0%,
    rgba(235, 235, 235, 1) 100%
  );

  border-top: solid 1px #5d6d7e;
  padding: 4px;
  padding-top: 16px;
  border-bottom: 1px solid #888;

  /*font-weight: 600;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 8px;
    */
}

.adc-col-header2,
.adc-large {
  font-weight: 600;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  padding: 8px;
}

.adc-col-value {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

table.adc-table td div.col-ready {
  background: rgb(63, 112, 64);
  /*background: linear-gradient(0deg, rgba(63,112,64,1) 0%, rgba(51,212,48,1) 100%);*/
  background: -webkit-linear-gradient(
    top,
    #bfd255 0%,
    #8eb92a 50%,
    #72aa00 51%,
    #9ecb2d 100%
  );
  color: #fff;
}

table.adc-table td div.col-error {
  background: rgb(63, 112, 64);
  /*background: linear-gradient(0deg, rgba(63,112,64,1) 0%, rgba(51,212,48,1) 100%);*/
  background: -webkit-linear-gradient(
    top,
    #f85032 0%,
    #f16f5c 50%,
    #f6290c 51%,
    #f02f17 71%,
    #e73827 100%
  );
  color: #fff;
}

table.adc-table td div.col-delete {
  background: rgb(63, 112, 64);
  /*background: linear-gradient(0deg, rgba(63,112,64,1) 0%, rgba(51,212,48,1) 100%);*/
  background: -webkit-linear-gradient(
    top,
    #1e5799 0%,
    #2989d8 50%,
    #207cca 51%,
    #7db9e8 100%
  );
  color: #fff;
  padding-top: 1px;
}

table.adc-table td div.col-complete {
  background: rgb(63, 112, 64);
  /*background: linear-gradient(0deg, rgba(63,112,64,1) 0%, rgba(51,212,48,1) 100%);*/
  background: linear-gradient(
    0deg,
    rgba(24, 114, 135, 1) 0%,
    rgba(48, 219, 194, 1) 51%,
    rgba(24, 114, 135, 1) 100%
  );
  color: #fff;
  padding-top: 1px;
}

table.adc-table div.col-ready td.td-large {
  padding-top: 3px;
}

table.adc-table td div.col-lock {
  background: #ddc562;
  background: linear-gradient(
    0deg,
    rgba(198, 185, 124, 1) 0%,
    rgba(255, 222, 149, 1) 100%
  );
  color: #222;
  padding-top: 3px;
  font-weight: 400;
}

table.adc-table td div.col-ready {
  background: rgb(63, 112, 64);
  background: linear-gradient(
    0deg,
    rgba(63, 112, 64, 1) 0%,
    rgba(51, 212, 48, 1) 100%
  );

  color: #fff;
}

table.adc-table td.td-large div.col-ready {
  padding-top: 3px;
}

table.adc-table td div.col-review {
  background: rgb(204, 62, 200);
  background: -webkit-linear-gradient(
    top,
    #cb60b3 0%,
    #c146a1 50%,
    #a80077 51%,
    #db36a4 100%
  );
  color: #fff;
  padding-top: 3px;
  font-weight: 400;
}

table.adc-table td div.trans {
  margin: 0;
  padding: 0;
}

table.adc-table div.col-id {
  text-align: left;
  padding-left: 4px;
}

table.adc-table td.td-large div.col-id {
  padding-top: 3px;
}

table.adc-table td.td-small div {
  font-size: 10pt;
}

/*.coolScroll {

    scrollbar-width: thin;
    scrollbar-gutter: stable;


}*/

.coolScroll::-webkit-scrollbar {
  width: 20px;
  height: 20px;
  background-color: transparent;
  padding-left: 2px;
  padding-right: 2px;
}

.coolScroll::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
  min-height: 40px;
}

.coolScroll2::-webkit-scrollbar {
  width: 20px;
  height: 20px;
  background-color: #fff;
  padding-left: 2px;
  padding-right: 2px;
}

.coolScroll2::-webkit-scrollbar-thumb {
  background-color: #494949;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
  min-height: 10px;
}

.condition-group {
  background: linear-gradient(to bottom, #b2dce8 0%, #c7e9ed 100%);
  border: solid 1px #29a5a5;
  border-radius: 8px;
}

.autoCompleteExtenderSelect {
  padding: 0px;
  padding-top: 0px;
  border: solid 5px #b8ceea;
  border-radius: 5px;
  position: absolute;
  background: #fff;
  z-index: 100;
  max-height: 400px;
  overflow: scroll;
}

.autoCompleteExtenderSelectBigger {
  padding: 0px;
  padding-top: 0px;
  border: solid 5px #b8ceea;
  border-radius: 5px;
  position: absolute;
  background: #fff;
  z-index: 100;
  max-height: 400px;
  overflow: scroll;
}

.autoCompleteExtenderSelectBigger a {
  width: 215px;
  display: block;
  padding: 0px;
  padding-bottom: 4px;
  line-height: 15px;

  color: black;
  text-decoration: none;
}

.autoCompleteExtenderSelectBigger a span.keyValue {
  font-weight: 700;

  font-size: 14px;
  line-height: 14px;
}

.autoCompleteExtenderSelectBigger a:hover {
  background-color: #c0e2e3;
}

.autoCompleteExtenderSelectBigger a span.description {
  padding-left: 2px;
  font-weight: 300;
  font-size: 14px;
  line-height: 14px;
}

.autoCompleteExtenderSelectLines {
  padding: 0px;
  padding-top: 0px;
  border: solid 5px #b8ceea;
  border-radius: 5px;
  position: absolute;
  background: #fff;
  z-index: 100;
  max-height: 150px;
  overflow: scroll;
}

.autoCompleteExtenderSelectBiggerLines {
  padding: 0px;
  padding-top: 0px;
  border: solid 5px #b8ceea;
  border-radius: 5px;
  position: absolute;
  background: #fff;
  z-index: 100;
  max-height: 150px;
  overflow: scroll;
}

.linkReplacement {
  width: 200px;
  display: block;
  padding: 0px;
  padding-bottom: 0px;
  line-height: 14px;

  color: black;
  text-decoration: none;
}

.linkReplacement span.keyValue {
  font-weight: 700;

  font-size: 14px;
  line-height: 14px;
}

.linkReplacement span.description {
  padding-left: 2px;
  font-weight: 300;
  font-size: 14px;
  line-height: 14px;
}

.search-icon {
  position: absolute;
  right: 10px;
  top: 3px;
  color: #777;
}

.search-icon-line {
  position: absolute;
  right: 10px;
  top: 5px;
  color: #777;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}
